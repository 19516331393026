import { useLazyload } from 'vendor/useLazyload';
import '@kuzorov/smoothscroll-polyfill';
import AOS from 'aos';
import Swiper from 'swiper';
import SwiperCore, { Navigation, Autoplay, EffectFade } from 'swiper/core';
SwiperCore.use([Navigation, Autoplay, EffectFade]);


const observer = useLazyload();
observer.observe();

function init() {
    console.log("init");

    initSliderTeaserNews();

    initSliderHome();

    initSliders(); 

    //initForms();

    // cookies
    const $body = document.querySelector('body');
    $body.addEventListener('cookies:saved', handleCookies);
    let btn_cookies = document.querySelector('#edit-cookies');
    btn_cookies.addEventListener('click', function (e) {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('cookies:update', true, false);
        document.querySelector('body').dispatchEvent(event);
    });

    // navi
    hamburger.addEventListener("click", mobileMenu);
    const navLink = document.querySelectorAll(".nav-link");
    navLink.forEach(n => n.addEventListener("click", closeMenu));

    //btt
    btn_btt = document.getElementById("btt");
    btn_btt.addEventListener('click', topFunction);
    window.onscroll = function () { scrollFunction() };

    // aos
    AOS.init({
        offset: 30, // offset (in px) from the original trigger point
        duration: 1000,
    });
}

// COOKIES
function handleCookies(e) {
    var newcookies = e.detail.join();
    var oldcookies = decodeURIComponent(document.cookie).split('cookie_status=')[1];

    if (newcookies != oldcookies) {
        location.reload(true);
    }
}

// BACK TO TOP
var btn_btt;
function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        btn_btt.style.display = "block";
    } else {
        btn_btt.style.display = "none";
    }
}

function topFunction() {
    document.querySelector('.preheader').scrollIntoView({ behavior: 'smooth' });
}

// NAVIGATION
const hamburger = document.querySelector(".hamburger");
const navMenu = document.querySelector(".menu__container");
const bod = document.body;

function mobileMenu() {
    console.log("clicking");
    hamburger.classList.toggle("active");
    navMenu.classList.toggle("active");
    bod.classList.toggle("nav-open");
}

function closeMenu() {
    hamburger.classList.remove("active");
    navMenu.classList.remove("active");
    bod.classList.remove("nav-open");
}

// Teaser News
function initSliderTeaserNews() {
    const teaserSwiper = new Swiper('#swiper-teaser-news', {
        slidesPerView: 1,
        spaceBetween: 10,

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            540: {
                slidesPerView: 2,
                spaceBetween: 20
            }
        }
    });
}


// +++++++++++++++++++++++ SLIDERS
function initSliderHome() {
    const teaserHomeSwiper = new Swiper('.swiper-header-block', {
        slidesPerView: 1,
        loop: true,
        centeredSlides: true,
        autoplay: {
            delay: 3500,
            disableOnInteraction: true,
        },
        speed: 1000,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
    });
}




// +++++++++++++++++++++++ SLIDERS
var swiper;
function initSliders() {
    swiper = new Swiper('.swiper-block', {
        slidesPerView: 1,
        spaceBetween: 10,

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            540: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 25
            }
        }
    });

    document.addEventListener('click', function (event) {
        var el = event.target;
        if (!el.matches('.marker')) return;
        event.preventDefault();
        el.classList.toggle('active');
        filterSlides(el);
    });
}

// FILTER
var sliderDelay = 350;
var delayBetweenAnis = 100;
var cssAni = 150;
var activeFilters = [];
function filterSlides(target) {
    var key = target.getAttribute('title');
    var slides = document.querySelectorAll('section.sliders .swiper-slide.' + key);
    if (activeFilters.includes(key)) {
        // filter was already active
        slides.forEach(element => {
            element.classList.remove('slide-hidden');

            setTimeout(function () {
                element.classList.remove('slide-fade-out');
            }, delayBetweenAnis);
        });

        const index = activeFilters.indexOf(key);
        if (index > -1) {
            activeFilters.splice(index, 1);
        }

        updateSliders(sliderDelay);
    }
    else {
        // filter the slides
        slides.forEach(element => {
            element.classList.add('slide-fade-out');

            setTimeout(function () {
                element.classList.add('slide-hidden');
            }, delayBetweenAnis + cssAni);
        });

        activeFilters.push(key);

        updateSliders(sliderDelay);
    }
}

function showAllSlides() {
    var slides = document.querySelectorAll('section.sliders .swiper-slide');

    slides.forEach(element => {
        element.classList.remove('slide-hidden');

        setTimeout(function () {
            element.classList.remove('slide-fade-out');
        }, delayBetweenAnis);
    });

    updateSliders(sliderDelay);
}

var sliderTO;
function updateSliders(delay) {
    sliderTO = setTimeout(function () {
        swiper.forEach(element => {

            var stillActive = element.wrapperEl.querySelectorAll(".swiper-slide:not(.slide-hidden):not(.empty)");
            var emptycard = element.wrapperEl.querySelector(".empty");

            if (stillActive.length == 0) {
                emptycard.classList.remove('slide-hidden');

                setTimeout(function () {
                    emptycard.classList.remove('slide-fade-out');
                }, delayBetweenAnis);
            }
            else {
                emptycard.classList.add('slide-fade-out');

                setTimeout(function () {
                    emptycard.classList.add('slide-hidden');
                }, delayBetweenAnis + cssAni);
            }

            element.update();

        });
    }, delay);
}


init();